import React from "react"

import Layout from "../components/layout"
import Head from "../components/Head"
import { graphql } from "gatsby"
import COUNTRY from "../constants";

export default function PricePage({ data }) {

  const post = data.markdownRemark
  const date = post.frontmatter.date
  return (
    <Layout
      country={COUNTRY.BY}
      hotLine={data.site.siteMetadata.countries.by.hotLine}
      countrySwitchOff={true}
    >
      <Head
        lang={COUNTRY.BY}
        title={"Размер лицензионных вознаграждений Allsports " + date}
        slug={post.fields.slug}
      />
      <section>
        <h1>{post.frontmatter.title}</h1>
        <small>{date}</small>
        <div dangerouslySetInnerHTML={{ __html: post.html }} />
      </section>
    </Layout>
  )
}

export const query = graphql`
  query PriceQuery($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      fields {
        slug
      }
      frontmatter {
        title
        date(formatString: "DD.MM.YYYY")
        country
      }
    }
     site {
        siteMetadata {
          countries {
            by {
              hotLine {
                tel
                title
              }
            }
          }
        }
     },
  }
`
